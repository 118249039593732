<template>
  <form novalidate
        @submit.prevent="save">
    <md-card>
      <md-card-header>
        <div class="md-layout md-alignment-center-space-between">
          <h1 class="md-title md-layout-item md-size-40">Edit Unlisted Site</h1>
          <div class="md-layout-item md-size-10">
            <md-button class="md-accent md-raised" type="submit">Save</md-button>
          </div>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout-item md-size-25">
              <md-field>
                <label for="updated_at">Last Updated</label>
                <md-input disabled name="updated_at" id="updated_at" v-model="form.updated_at"/>
              </md-field>
        </div>
        <div class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-10 upload-admin-unlistedsite">
            <i class="far fa-image" @click="$refs.file.click()" style="font-size: 20px;">
              <p class="icons-description"> Upload New Logo</p>
            </i>
            <input type="file" ref="file" id="file" class="filestyle" data-count="0" style="display: none;"
                   @change="onFileChange">
          </div>
          <div class="md-layout-item md-size-100">
            <div class="image-preview" styl="justify-content: flex-start;">
              <img class="cell-image-preview" :src="form.imagePreview" v-show="form.imagePreview" style="width:20%;">
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { FETCH_UNLISTED_SITE } from '@/store/actions.type';
import { UPDATE_UNLISTED_SITE } from '@/store/modules/admin/resources/unlistedSites/unlistSitesActions.type';

export default {
  name: 'Site',
  props: {
    id: [String, Number],
  },
  data: () => ({
    form: {
      id: null,
      site_id: null,
      images: null,
      imagePreview: null,
      file: null,
      updated_at: null,
    },
    site: {
      id: null,
      public_path: null,
    },
    file: '',
  }),
  computed: {
    unlistedSiteData() {
      return this.$store.getters.unlistedSiteData;
    },
  },
  mounted() {
    this.initSiteData(this.id);
  },
  methods: {
    initSiteData(id) {
      this.fetchUnlistedSite(id);
    },
    fetchUnlistedSite(id) {
      this.$store.dispatch(FETCH_UNLISTED_SITE, id)
        .then((response) => {
          this.form.imagePreview = response.data.site.images ? response.data.site.images : response.data.site.site.public_path;
          this.form.id = response.data.site.id;
          this.form.site_id = response.data.site.site_id;
          this.form.updated_at = response.data.site.updated_at;
          this.$log.info('FETCH_UNLISTED_SITE', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_UNLISTED_SITE', error);
        });
    },
    clearForm() {
      this.$v.$reset();
      this.form.imagePreview = null;
    },
    onFileChange(e) {
      const image = e.target.files[0];
      this.form.file = image;
      this.createImage(image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    save() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('id', this.form.id);
      formData.append('sited_id', this.form.site_id);
      formData.append('context', 'UNLISTED_SITE_IMAGE');
      this.$store.dispatch(UPDATE_UNLISTED_SITE, { id: this.form.id, formData })
        .then(() => {
          this.$router.push({ name: 'adminUploadedWebsitesListing' });
        })
        .catch((error) => {
          this.$log.error(UPDATE_UNLISTED_SITE, error);
        });
    },
  },
  watch: {
    id: {
      handler(val) {
        this.initSiteData(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.fa-image:before {
  font-size: 45px;
  margin: 0 auto 0 55px;
}

.upload-admin-unlistedsite {
  padding-left: 5px;
  padding-right: 5px;
  margin: 50px auto 0;
  border: 2px solid black;
  border-radius: 5px;
}

.upload-admin-unlistedsite:hover {
  background-color: lightgray;
  color: white;
}
</style>

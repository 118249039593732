<template>
  <div>
    <form novalidate
          @submit.prevent="validateLogin">
      <md-card>
        <md-card-header>
          <div class="md-layout md-alignment-center-space-between">
            <h1 class="md-title md-layout-item md-size-40">{{isEditMode ? 'Edit' : 'Create New'}} User</h1>
            <div class="md-layout-item md-size-10">
              <md-button class="md-accent md-raised" type="submit">Save</md-button>
            </div>
          </div>
        </md-card-header>

        <md-card-content style="padding: 20px">
          <div class="md-layout md-alignment-center-space-between">
            <div class="md-layout-item md-size-30">
              <md-field :class="[getValidationClass('username'),'md-field-user']">
                <label for="username">Username</label>
                <md-input name="username" id="username" class="username" v-model="form.username" :disabled="isLoading"
                          @focus="clearErrors('username')" placeholder="Username"/>
                <div class="md-error" v-if="!$v.form.username.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30" v-if="!isEditMode">
              <md-field>
                <label for="password">Password</label>
                <md-input name="password" id="password" type="password" v-model="form.password" />
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field :class="[getValidationClass('email'),'md-field-user']">
                <label for="email">Email</label>
                <md-input name="email" id="email" type="email" v-model="form.email"/>
                <div class="md-error" v-if="!$v.form.email.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="name">Name</label>
                <md-input name="name" id="name" v-model="form.name"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field :class="[getValidationClass('role'),'md-field-user']">
                <label for="role" v-show="!form.role">User Role</label>
                <select v-model="form.role" name="role" id="role" style=" outline: none; border: none; width:150%;">
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="wuser">Wuser</option>
                </select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="tabs-count">#Tabs</label>
                <md-input disabled name="tabs-count" id="tabs-count" v-model="form.tabsCount"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="country">Country</label>
                <md-input name="country" id="country" v-model="form.country"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="created-at">Created At</label>
                <md-input disabled name="create-at" id="created-at" v-model="form.created_at"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="last-login">Last login</label>
                <md-input disabled name="last-login" id="last-login" v-model="form.last_login"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="ip">IP</label>
                <md-input disabled name="ip" id="ip" v-model="form.ip"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field>
                <label for="bio">Bio</label>
                <md-input name="bio" id="bio" v-model="form.bio"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="plan">Plan</label>
                <md-input disabled name="plan" id="plan" v-model="form.plan"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="is-premium">Is Premium</label>
                <md-input disabled name="is-premium" id="is-premium" v-model="form.isPremium"/>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label for="premium" v-show="!form.premium">Premium User</label>
                <label for="premium" v-if="showPremiumDate">{{form.premium}}</label>
                <select v-model="form.premium" name="premium" id="premium" style=" outline: none; border: none; width:150%;">
                  <option value="+14 days">14 days</option>
                  <option value="+1 month">1 Month</option>
                  <option value="+3 months">3 Months</option>
                  <option value="+6 months">6 Months</option>
                  <option value="+12 months">12 Months</option>
                  <!-- in BE for premium column, will save todays' date as a value of adding -1 then tomorrow will be expired-->
                  <option value="-1">Expired</option>
                </select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-checkbox v-model="form.privacy">Keep account private</md-checkbox>
            </div>
            <div class="md-layout-item md-size-20">
              <md-checkbox v-model="form.isInMenu">In Menu</md-checkbox>
            </div>
            <div class="md-layout-item md-size-20">
              <md-checkbox v-model="form.privacynotifylogo">Receive notifications</md-checkbox>
            </div>
            <div class="md-layout-item md-size-20">
              <md-checkbox v-model="form.deleted_at">Softe Delete</md-checkbox>
            </div>
            <div class="md-layout-item md-size-20">
              <md-checkbox v-model="form.nlsignup">Receive updates about Wibki</md-checkbox>
            </div>
            <div class="md-layout-item md-size-70" style="margin-left:-10px;">
              <md-button @click="resetPassword" class="md-raised md-accent forgot-auth-btn" :disabled="isLoading">
                Reset Password
              </md-button>
              <regenerate-sprite-btn componentView="btn" :userId="userId"></regenerate-sprite-btn>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>

    <div class="md-layout md-alignment-center-center" id="my-faves" style="margin-top: 20px;">
      <div class="md-layout-item md-size-100 user-top-tabs-faves">
        <UserTopTabs :publicUsername="publicUsername" :adminMode="adminMode"></UserTopTabs>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import UserTopTabs from '@/components/common/UserTopTabs.vue';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { UPDATE_USER } from '@/store/modules/user/userActions.type';
import { CREATE_USER, FETCH_ADMIN_USER } from '@/store/modules/admin/resources/users/usersActions.type';
import { FORGOT_PASSWORD } from '@/store/modules/auth/authActions.type';
import RegenerateSpriteBtn from '@/components/common/user/RegenerateSpriteBtn.vue';

export default {
  name: 'User',
  components: {
    UserTopTabs,
    RegenerateSpriteBtn,
  },
  props: {
    id: [String, Number],
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      username: null,
      email: null,
      nlsignup: false,
      privacy: false,
      privacynotifylogo: false,
      name: null,
      role: null,
      country: null,
      password: null,
      deleted_at: false,
      premium: null, // expired - dropdown field
      ip: null,
      created_at: null,
      last_login: null,
      bio: null,
      plan: null,
      tabsCount: null,
      isPremium: 'NO',
      isInMenu: false,
    },
    publicUsername: null,
    adminMode: false,
    initialPremium: null, // this value only to show the premum date or the selected period from drop down
  }),
  validations: {
    form: {
      username: {
        required,
      },
      email: {
        required,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    showPremiumDate() {
      return this.form.premium === this.initialPremium;
    },
    userData() {
      return this.$store.getters.userData;
    },
    userId() {
      return this.form.id;
    },
  },
  created() {
    this.initUserData(this.id);
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
    },
    validateLogin() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.save();
      }
    },
    initUserData(id) {
      if (id !== null) {
        this.isEditMode = true;
        this.fetchUser(id);
      } else {
        this.isEditMode = false;
      }
    },
    fetchUser(id) {
      this.$store.dispatch(FETCH_ADMIN_USER, id)
        .then((response) => {
          this.form.id = response.data.user.id;
          this.form.username = response.data.user.username;
          this.form.email = response.data.user.email;
          this.form.nlsignup = !!response.data.user.nlsignup;
          this.form.privacy = !!response.data.user.privacy;
          this.form.privacynotifylogo = !!response.data.user.privacynotifylogo;
          this.form.name = response.data.user.name;
          this.form.role = response.data.user.role;
          this.form.country = response.data.user.country;
          this.publicUsername = response.data.user;
          this.form.deleted_at = !!response.data.user.deleted_at;
          this.form.created_at = response.data.user.created_at;
          this.form.ip = response.data.user.ip;
          this.form.last_login = response.data.user.last_login;
          this.form.bio = response.data.user.bio;
          this.form.premium = response.data.user.premium;
          this.initialPremium = response.data.user.premium;
          this.form.isPremium = response.data.user.premium != null ? 'YES' : 'NO';
          this.form.plan = response.data.user.plan;
          this.form.tabsCount = response.data.user.userTabsCount;
          this.form.isInMenu = !!response.data.user.is_in_menu;
          this.adminMode = true;
          this.$log.info('FETCH_ADMIN_USER', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_ADMIN_USER', error);
        });
    },
    save() {
      const payload = {
        id: this.form.id ?? null,
        username: this.form.username ?? null,
        email: this.form.email ?? null,
        nlsignup: this.form.nlsignup ?? 0,
        privacy: this.form.privacy ?? 0,
        privacynotifylogo: this.form.privacynotifylogo ?? 0,
        name: this.form.name ?? null,
        role: this.form.role ?? null,
        country: this.form.country ?? null,
        password: this.form.password ?? null,
        deleted_at: this.form.deleted_at,
        should_confirm: true,
        premium: this.form.premium,
        bio: this.form.bio,
        is_in_menu: this.form.isInMenu,
      };
      this.$store.dispatch(this.isEditMode ? UPDATE_USER : CREATE_USER, payload)
        .then((response) => {
          this.$log.info(this.isEditMode ? 'UPDATE_USER' : 'CREATE_USER', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
          this.$router.push('/admin/users');
        })
        .catch((error) => {
          this.$log.error(this.isEditMode ? 'UPDATE_USER' : 'CREATE_USER', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    resetPassword() {
      const payload = {
        username: this.form.username,
      };
      this.$store.dispatch(FORGOT_PASSWORD, payload).then((response) => {
        this.$log.info(FORGOT_PASSWORD, payload);
        this.$toasted.show(response.message, {
          type: 'success',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
      }).catch((error) => {
        this.$log.error(FORGOT_PASSWORD, error);
        this.$toasted.show(error.message, {
          type: 'error',
          position: 'top-right',
          duration: 5000,
          icon: 'alert',
        });
        if (error.status === 'validation_error' && error.errors) {
          this.errors = error.errors;
        }
      });
    },
  },
  watch: {
    id: {
      handler(val) {
        this.initUserData(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <form novalidate @submit.prevent="validateFavPage">
    <md-card>
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-90">
            <h1 class="md-title">{{ isEditMode ? 'Edit' : 'Create New' }} FavPage</h1>
          </div>
          <div class="md-layout-item md-size-10">
            <md-button class="md-accent md-raised" type="submit">Save</md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content id="favpage" class="fav-page-admin">

        <!--SUBDOMAIN / DOMAIN / TLD / COUNTRY / TYPE-->
        <div class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-20">
            <md-field :class="[getValidationClass('subdomain'),'md-field-site']">
              <label for="subdomain">Subdomain</label>
              <md-input name="subdomain" id="subdomain" class="subdomain" v-model="form.subdomain" :disabled="isLoading"
                        @focus="clearErrors('subdomain')" placeholder="Subdomain"/>
              <div class="md-error" v-if="!$v.form.subdomain.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>

          <div class="md-layout-item md-size-20">
            <md-field :class="['md-field-site']">
              <label for="domain">Domain</label>
              <md-input name="domain" id="domain" class="domain" v-model="form.domain" placeholder="Domain"
                        disabled/>
            </md-field>
          </div>

          <div class="md-layout-item md-size-10">
            <md-field :class="['md-field-site']">
              <label for="tld">Tld</label>
              <md-input name="tld" id="tld" class="tld" v-model="form.tld" disabled placeholder="Tld"/>
            </md-field>
          </div>

          <div class="md-layout-item md-size-10">
            <md-field :class="[getValidationClass('country'),'md-field-site']">
              <label for="country">Country</label>
              <md-select name="gender" id="gender" class="country" v-model="form.country" md-dense
                         :disabled="isLoading || isEditMode">
                <md-option value="US">US</md-option>
                <md-option value="CA">CA</md-option>
                <md-option value="FR">FR</md-option>
                <md-option value="IN">IN</md-option>
                <md-option value="UK">UK</md-option>
              </md-select>
              <div class="md-error" v-if="!$v.form.country.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>

          <div class="md-layout-item md-size-20">
            <md-field :class="[getValidationClass('type'),'md-field-site']">
              <label for="type">Type</label>
              <md-select name="type" id="type" class="type" v-model="form.type" md-dense
                         :disabled="isLoading || isEditMode"
                         @focus="clearErrors('type')">
                <md-option value="TYPE_GAMES">TYPE_GAMES</md-option>
                <md-option value="TYPE_BLOGS">TYPE_BLOGS</md-option>
                <md-option value="TYPE_CATEGORY">TYPE_CATEGORY</md-option>
                <md-option value="TYPE_LANG">TYPE_LANG</md-option>
                <md-option value="TYPE_TOP_FAVES">TYPE_TOP_FAVES</md-option>
                <md-option value="TYPE_OTHER">TYPE_OTHER</md-option>
                <md-option value="TYPE_DEFAULT">TYPE_DEFAULT</md-option>
              </md-select>
              <div class="md-error" v-if="!$v.form.type.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>
        </div>

        <!--TITLE / KEYWORDS-->
        <div class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-25">
            <md-field :class="[getValidationClass('title'),'md-field-site']">
              <label for="title">Title</label>
              <md-input name="title" id="title" class="title" v-model="form.title" :disabled="isLoading"
                        @focus="clearErrors('title')" placeholder="Title"/>
              <div class="md-error" v-if="!$v.form.title.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>

          <div class="md-layout-item md-size-70">
            <md-field :class="[getValidationClass('keywords'),'md-field-site']">
              <label for="keywords">Keywords</label>
              <md-input name="keywords" id="keywords" class="keywords" v-model="form.keywords" :disabled="isLoading"
                        @focus="clearErrors('keywords')" placeholder="Keywords"/>
              <div class="md-error" v-if="!$v.form.keywords.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>
        </div>

        <!--DESCRIPTION-->
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field :class="[getValidationClass('description'),'md-field-site']">
              <label for="description">Description</label>
              <md-input name="description" id="description" class="description" v-model="form.description"
                        :disabled="isLoading"
                        @focus="clearErrors('description')" placeholder="Description"/>
              <div class="md-error" v-if="!$v.form.description.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
          </div>
        </div>

        <!--DATES-->
        <div v-if="isEditMode" class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-45">
            <md-field :class="['md-field-site']">
              <label for="createdAt">Created at</label>
              <md-input name="createdAt" id="createdAt" class="createdAt" v-model="form.createdAt"
                        placeholder="Created at" disabled/>
            </md-field>
          </div>

          <div class="md-layout-item md-size-45">
            <md-field :class="['md-field-site']">
              <label for="updatedAt">Updated at</label>
              <md-input name="updatedAt" id="updatedAt" class="updatedAt" v-model="form.updatedAt"
                        placeholder="Updated at" disabled/>
            </md-field>
          </div>

          <div class="md-layout-item md-size-45">
            <md-field :class="['md-field-site']">
              <label for="updatedAt">Updated at</label>
              <md-input name="updatedAt" id="updatedAt" class="updatedAt" v-model="form.updatedAt"
                        placeholder="Updated at" disabled/>
            </md-field>
          </div>
        </div>
        <md-checkbox v-model="form.enabled">Enabled</md-checkbox>

        <vue-editor :disabled="!bannerActive" :editorToolbar="customToolbar" v-model="form.banner"></vue-editor>

        <div class="md-layout md-alignment-center-space-between"
             style="border-bottom: 1px solid black; border-top: 1px solid black; padding: 20px;">
          <div class="md-layout-item md-size-100">
            <md-button class="md-raised md-primary add-new-fav-line-btn" @click="openFavModal()"
                       style="font-size: 16px; align-items: center; text-align: center; margin: 0 auto; justify-content: center; display: flex; padding-right: 10px; border-radius: 6px;">
              <i class="far fa-plus-square">&nbsp;Add New Fav Line</i>
            </md-button>
          </div>
        </div>

        <!--PREVIEW-->
        <draggable v-if="isEditMode"
                   v-model="favPageData.lines"
                   handle=".editable"
                   style="display: inline-block; width: 100%; margin-top: 20px;"
                   @change="onMoveChange">
          <div v-for="line in favPageData.lines"
               v-bind:key="line.name"
               class="logo-container md-layout"
               v-show="!hide"
               :style="(line.bgcolor !== '#ffffff') ? {'background-color': line.bgcolor, 'border-radius': '6px'} : {'background-color': line.bgcolor}">
            <div class="md-layout-item md-small-size-100 md-xsmall-size-100 single-logo md-size-10"
                 style="display: flex; flex-flow: row;">
              <i class="fas fa-grip-lines"></i>
              <a v-if="line.url" :href="getLineLink(line.url)" target="_blank">
                <p v-html="line.name"></p>
              </a>
              <p v-else v-html="line.name"></p>
              <md-button class="md-flat md-primary md-icon-button fav-line-btns" @click="onEdit(line)"
                         style="margin: 0; margin-left: -5px;">
                <i class="fas fa-edit"></i>
              </md-button>
              <md-button class="md-flat md-primary md-icon-button fav-line-btns"
                         style="margin: 0; margin-left: -15px;"
                         @click="deleteFavLineConfirmation = true; deleteLineId = line.id">
                <i class="fas fa-trash"></i>
              </md-button>
            </div>
            <div
              class="md-layout-item favcells-container  md-size-90 md-small-size-100 md-small-size-100 editable">
              <div class="md-layout favcell-line-container">
                <draggable  v-if="true"
                            style="display: flex;"
                            v-model="line.cells"
                            @change="(event) => onCellMoveChange(event, line)">
                <div v-for="(cell) in line.cells"
                     :key="cell.id"
                     :id="'cell-'+cell.id" :position="cell.position"
                     class="md-layout-item single-logo-container" :style="{'max-width':  cell.width+'px', 'flex-grow': '0',
                    'width': cell.width+'px', 'margin-right': line.space+'px' }">
                  <a  :href="!editableCell ? cell.url : 'javascript:;'" :title="cell.alt"
                     :target="!editableCell ? '_blank' : ''">
                    <div class="cell-container draggable edit-cell"
                         v-show="(editableCell)">
                      <i class="far fa-edit" @click="openFavCellModal(line, cell)" :style="{'width': cell.width+'px'}"></i>
                      <i class="fas fa-trash" @click="deleteFavCellConfirmation = true;
                          deleteLineId = line.id; deleteCellId = cell.id"></i>
                    </div>
                    <div :class="(editableCell) ? 'cell-container-faves opacity-link' : 'cell-container-faves '"
                         :style="{ 'background-image': 'url(' + line.public_path + ')', 'background-position': -(cell.startPosition) + 'px 0',
                    'width': cell.width+'px', 'height':cell.height+'px', 'background-color': 'white' }">
                    </div>
                  </a>
                </div>
                </draggable>
                <div class="cell-add-edit-container md-layout-item" slot="footer">
                  <md-button class="md-icon-button edit-cell-button" @click="editButtons">
                    <md-icon><i class="fas fa-edit"></i></md-icon>
                  </md-button>
                  <md-button class="md-icon-button add-cell-button" @click="openFavCellModal(line, null)">
                    <md-icon><i class="far fa-plus-square"></i></md-icon>
                  </md-button>
                </div>
              </div>
            </div>

          </div>
        </draggable>

        <div class="md-layout md-size-100" v-else>
          <div class="md-layout-item no-data-container">
            <p>We don't have any data for this page!</p>
          </div>
        </div>

        <!--HIDE / SHOW-->
        <div v-if="isEditMode" class="md-layout md-size-100 md-alignment-center-center">
          <div class="md-layout-item fave-button-container" v-if="!hide">
            <i class="fas fa-chevron-up"></i>
            <md-button class="fav-button" @click="toggleFavList">{{ $t('faves.hide') }}</md-button>
          </div>
          <div class="md-layout-item fave-button-container" v-else>
            <button class="fa-button" @click="toggleFavList"><i class="fas fa-chevron-down"></i></button>
            <md-button class="fav-button" @click="toggleFavList">{{ $t('faves.show') }}</md-button>
          </div>
        </div>
        <FavLineModal :isFavModalOpen="isFavModalOpen" @close="closeModal" @reloadData="reloadData"
                      :isEditableLine="isEditableLine"></FavLineModal>
        <FavLineCellModal :isFavModalOpen="isFavCellModalOpen" @close="closeModal" :isEditableLineCell="isEditableLineCell"
                          @reloadData="reloadData" :favLineObj="favLineObj"></FavLineCellModal>
      </md-card-content>
      <md-dialog-confirm class="account-delete-confirmation delete-tab"
                         :md-active.sync="deleteFavLineConfirmation"
                         md-title="Delete Line"
                         :md-content="deleteItem"
                         md-confirm-text="Yes"
                         md-cancel-text="No"
                         @md-confirm="onRemove(deleteLineId)"/>

      <md-dialog-confirm class="account-delete-confirmation delete-tab"
                         :md-active.sync="deleteFavCellConfirmation"
                         md-title="Delete Cell"
                         :md-content="deleteCellText"
                         md-confirm-text="Yes"
                         md-cancel-text="No"
                         @md-confirm="onRemoveCell(deleteLineId, deleteCellId)"/>
    </md-card>
  </form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { required } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import {
  ADMIN_DELETE_FAV_LINE,
  ADMIN_FETCH_FAV_PAGE,
  ADMIN_RESET_FAV_PAGE,
} from '@/store/modules/admin/single/favPage/favPageActions.type';
import {
  ADMIN_CREATE_FAV_PAGE,
  ADMIN_UPDATE_FAV_LINE_POSITION,
  ADMIN_UPDATE_FAV_PAGE,
  ADMIN_DELETE_FAV_CELL_FOR_FAVLINE,
  ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE,
} from '@/store/modules/admin/resources/favPages/favPagesActions.type';

export default {
  name: 'FavPage',
  components: {
    draggable,
    FavLineModal: () => import('@/components/modals/FavLineModal.vue'),
    FavLineCellModal: () => import('@/components/modals/FavLineCellModal.vue'),
    VueEditor,
  },
  props: {
    id: String,
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      subdomain: null,
      domain: 'Wibki',
      tld: 'com',
      country: 'US',
      type: 'TYPE_CATEGORY',
      title: null,
      description: null,
      keywords: null,
      banner: null,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      enabled: false,
    },
    bannerActive: false,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ align: 'center' }, { align: 'justify' }, { align: 'right' }, { align: null }],
      ['blockquote', 'code-block'],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
    isFavModalOpen: false,
    isFavCellModalOpen: false,
    adminMode: false,
    editable: true,
    windowWidth: null,
    editableCell: false,
    editableCellMobile: false,
    hide: false,
    deleteFavLineConfirmation: false,
    deleteFavCellConfirmation: false,
    deleteLineId: null,
    deleteCellId: null,
    isEditableLine: null,
    isEditableLineCell: null,
    cellPos: null,
    favLineObj: null,
  }),
  validations: {
    form: {
      subdomain: {
        required,
      },
      country: {
        required,
      },
      type: {
        required,
      },
      title: {
        required,
      },
      description: {
        required,
      },
      keywords: {
        required,
      },
    },
  },
  computed: {
    deleteItem() {
      return 'Do You want To delete this favLine?';
    },
    deleteCellText() {
      return 'Do You want To delete this favCell?';
    },
    favPageData() {
      return this.$store.getters.adminFavPageData;
    },
    categories() {
      return this.$store.getters.adminFavPageData.lines;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(ADMIN_RESET_FAV_PAGE)
      .then();
    next();
  },
  methods: {
    reloadData() {
      this.initFavPageData(this.id);
      this.isEditableLine = null;
    },
    getLineLink(url) {
      const pattern = /^((http|https):\/\/)/;
      return pattern.test(url) ? url : `${window.location.origin}/${url}`;
    },
    openFavModal() {
      this.isFavModalOpen = !this.isFavModalOpen;
    },
    openFavCellModal(favLineObj, cellObj) {
      this.favLineObj = favLineObj;
      this.isEditableLineCell = cellObj;
      this.isFavCellModalOpen = !this.isFavCellModalOpen;
    },
    closeModal() {
      this.isFavModalOpen = false;
      this.isFavCellModalOpen = false;
      this.isEditableLine = null;
    },
    toggleFavList() {
      this.hide = !this.hide;
    },
    editButtons() {
      this.editableCell = !this.editableCell;
    },
    initFavPageData(id) {
      if (id !== null) {
        this.isEditMode = true;
        this.fetchFavPage(id);
      } else {
        this.isEditMode = false;
      }
    },
    fetchFavPage(id) {
      const idToUse = id.slice(0, -1);
      this.$store.dispatch(ADMIN_FETCH_FAV_PAGE, idToUse)
        .then((response) => {
          this.form.id = response.data.favPage.id;
          this.form.subdomain = response.data.favPage.subdomain;
          this.form.domain = response.data.favPage.domain;
          this.form.tld = response.data.favPage.tld;
          this.form.country = response.data.favPage.country;
          this.form.type = response.data.favPage.type;
          this.form.title = response.data.favPage.title;
          this.form.description = response.data.favPage.description;
          this.form.keywords = response.data.favPage.keywords;
          this.form.createdAt = response.data.favPage.created_at;
          this.form.updatedAt = response.data.favPage.updated_at;
          this.form.weekly = response.data.favPage.weekly;
          this.form.banner = response.data.favPage.banner !== null ? decodeURI(response.data.favPage.banner) : null;
          this.bannerActive = true;
          this.form.enabled = !response.data.favPage.disabled;
          this.$log.info('ADMIN_FETCH_FAV_PAGE', response);
        })
        .catch((error) => {
          this.$log.error('ADMIN_FETCH_FAV_PAGE', error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    validateFavPage() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.save();
      }
    },
    save() {
      const params = {
        id: this.form.id ?? null,
        subdomain: this.form.subdomain,
        country: this.form.country,
        type: this.form.type,
        title: this.form.title,
        description: this.form.description,
        keywords: this.form.keywords,
        weekly: this.form.weekly,
        banner: encodeURI(this.form.banner),
        disabled: this.form.enabled === false,
      };
      this.$store.dispatch(this.isEditMode ? ADMIN_UPDATE_FAV_PAGE : ADMIN_CREATE_FAV_PAGE, params)
        .then((response) => {
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.$log.info(this.isEditMode ? ADMIN_UPDATE_FAV_PAGE : ADMIN_CREATE_FAV_PAGE, response);
          this.$router.push({ name: 'adminFavPagesListing' });
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    onEdit(lineObj) {
      this.isEditableLine = lineObj;
      this.openFavModal();
    },
    onRemove(lineId) {
      const payload = {
        lineId,
        pageId: this.favPageData.id.slice(0, -1),
      };
      this.$store.dispatch(ADMIN_DELETE_FAV_LINE, payload)
        .then((response) => {
          this.$log.info('ADMIN_DELETE_FAV_LINE', response);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.initFavPageData(this.id);
          this.deleteLineId = null;
        })
        .catch((error) => {
          this.$log.error('ADMIN_DELETE_FAV_LINE', error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    onMoveChange(itemMoved) {
      const pageIdToUse = this.id.slice(0, -1);
      const payload = {
        pageId: pageIdToUse,
        id: itemMoved.moved.element.id,
        position: itemMoved.moved.newIndex + 1,
      };

      this.$store.dispatch(ADMIN_UPDATE_FAV_LINE_POSITION, payload)
        .then((response) => {
          this.$log.info(ADMIN_UPDATE_FAV_LINE_POSITION, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(ADMIN_UPDATE_FAV_LINE_POSITION, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    onRemoveCell(lineId, cellId) {
      const payload = {
        lineId,
        cellId,
        pageId: this.favPageData.id.slice(0, -1),
      };
      this.$store.dispatch(ADMIN_DELETE_FAV_CELL_FOR_FAVLINE, payload)
        .then((response) => {
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.initFavPageData(this.id);
          this.deleteCellId = null;
          this.deleteLineId = null;
        })
        .catch((error) => {
          this.$log.error('ADMIN_DELETE_FAV_CELL', error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    onCellMoveChange(itemMoved, line) {
      const pageIdToUse = this.id.slice(0, -1);
      const payload = {
        pageId: pageIdToUse,
        lineId: line.id,
        id: itemMoved.moved.element.id,
        position: itemMoved.moved.newIndex + 1,
      };
      this.$store.dispatch(ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE, payload)
        .then((response) => {
          this.$log.info(ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
  watch: {
    id: {
      handler(val) {
        this.initFavPageData(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/favpage.scss';
</style>

<template>
  <form novalidate
        @submit.prevent="validateSite"  enctype="multipart/form-data">
    <md-card id="adminSite">
      <md-card-header>
        <div class="md-layout md-alignment-center-space-between">
          <h1 class="md-title md-layout-item md-size-40">{{ isEditMode ? `Edit Site #${form.id}` : 'Create Site' }}</h1>
          <div class="md-layout-item md-size-10">
            <md-button class="md-accent md-raised" type="submit" >Save</md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-alignment-top-space-between">
          <div class="md-layout-item md-size-10">
            <h2>Image</h2>
            <img class="cell-image-preview" :src="form.public_path" v-show="form.public_path">
          </div>
          <div class="md-layout-item md-size-30">
            <h2>Statistics</h2>
            <h3>Appears on {{form.tab_cells_count}} Tab Cells</h3>
            <h3>Appears on {{form.unlisted_sites_count}} Unlisted Sites</h3>
          </div>
          <div class="md-layout-item md-size-60">
            <h2>Details</h2>
            <md-field :class="[getValidationClass('address'),'md-field-site']">
              <label for="address">Site</label>
              <md-input name="address" id="address" class="address" v-model="form.address" :disabled="isLoading"
                        @focus="clearErrors('address')" placeholder="Link" />
              <div class="md-error" v-if="!$v.form.address.required">
                {{ $t('missingValue') }}
              </div>
            </md-field>
            <md-checkbox v-model="form.favoriteSite">Favorite Site For First Login User</md-checkbox>
            <div class="md-layout image-container">
              <div class="md-layout-item md-size-20 image-button">
                <i class="far fa-image" @click="$refs.file.click()" style="font-size: 18px;">
                  <p class="icons-description"> Upload New Logo</p>
                </i>
                <input type="file" ref="file" id="file" class="filestyle" data-count="0" style="display: none;"
                       @change="onFileChange">
              </div>
              <div class="md-layout-item md-size-40">
                <div class="image-preview">
                  <img class="cell-image-preview" :src="form.imagePreview" v-show="form.imagePreview">
                </div>
              </div>
              <div class="md-layout-item md-size-30">
                <div class="image-preview" style="justify-content: flex-start;">
                  <img class="cell-image-preview" :src="goEditorLogo" v-show="goEditorLogo">
                </div>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import {
  FETCH_SITE,
  UPDATE_SITE,
  CREATE_ADMIN_SITE,
} from '@/store/modules/admin/single/site/siteActions.type';

export default {
  name: 'Site',
  props: {
    id: [String, Number],
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      address: null,
      fileId: null,
      file: null,
      image: null,
      imagePreview: null,
      favoriteSite: false,
    },
    siteExistsDisableButton: true,
    validUrl: false,
    file: '',
    goEditorLogo: null,
  }),
  validations: {
    form: {
      address: {
        required,
      },
    },
  },
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
  mounted() {
    this.initSiteData(this.id);
  },
  methods: {
    initSiteData(id) {
      if (id !== null) {
        this.isEditMode = true;
        this.fetchSite(id);
      } else {
        this.isEditMode = false;
      }
    },
    fetchSite(id) {
      this.$store.dispatch(FETCH_SITE, id)
        .then((response) => {
          if (response.data.site.favorite_site === 'Yes') {
            this.form.favoriteSite = true;
          } else {
            this.form.favoriteSite = false;
          }
          this.form.id = response.data.site.id;
          this.form.address = response.data.site.address;
          this.form.public_path = response.data.site.public_path;
          this.form.created_at = response.data.site.created_at;
          this.form.updated_at = response.data.site.updated_at;
          this.form.tab_cells_count = response.data.site.tab_cells_count;
          this.form.unlisted_sites_count = response.data.site.unlisted_sites_count;
          this.form.imagePreview = response.data.site.public_path;
          this.goEditorLogo = response.data.site.uploaded_logo_from_go_editor;
          this.$log.info('FETCH_SITE', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_SITE', error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    validateSite() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.save();
      }
    },
    onFileChange(e) {
      const image = e.target.files[0];
      this.form.file = image;
      this.createImage(image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    urlValidation(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(url);
    },
    save() {
      if (this.urlValidation(this.form.address)) {
        this.validUrl = false;
        const filters = {
          id: this.form.id ?? null,
          address: this.form.address,
        };
        const formData = new FormData();
        formData.append('file', this.form.file ?? '');
        formData.append('id', this.form.id);
        formData.append('link', this.form.address);
        formData.append('address', this.form.address);
        formData.append('context', 'ADMIN_SITE_IMAGE');
        formData.append('favorite_site', this.form.favoriteSite ?? true);
        this.$store.dispatch(this.isEditMode ? UPDATE_SITE : CREATE_ADMIN_SITE, { id: this.form.id, formData })
          .then((response) => {
            const item = response.data.site ?? null;
            const siteExists = response.data.error ?? null;
            if (item) {
              this.form.address = filters.address;
              this.form.fileId = item ? item.fileId : null;
              this.form.imagePreview = item ? item.iconpath : `${this.publicPath}Wibki-logo.png`;
              this.form.image = item ? item.iconpath : `${this.publicPath}Wibki-logo.png`;
              this.form.public_path = item ? item.iconpath : `${this.publicPath}Wibki-logo.png`;
              if (item.uploaded_logo_from_go_editor == null) {
                this.goEditorLogo = null;
              }
              this.$toasted.show(response.message, {
                type: 'success',
                duration: 5000,
                icon: 'check',
              });
              this.$log.info(this.isEditMode ? UPDATE_SITE : CREATE_ADMIN_SITE, response);
            } else if (siteExists) {
              this.$toasted.error(siteExists, {
                type: 'error',
                duration: 5000,
                icon: 'alert',
              });
            }
          })
          .catch((error) => {
            this.$toasted.show(error.message, {
              type: 'error',
              position: 'top-right',
              duration: 5000,
              icon: 'alert',
            });
            if (error.status === 'validation_error' && error.errors) {
              this.errors = error.errors;
            }
            this.$log.error(this.isEditMode ? UPDATE_SITE : CREATE_ADMIN_SITE, error);
          });
      } else {
        this.validUrl = true;
        this.$toasted.error('Url is not Valid!', {
          type: 'error',
          duration: 5000,
          icon: 'alert',
        });
      }
    },
  },
  watch: {
    id: {
      handler(val) {
        this.initSiteData(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/layout/admin-site-page.scss';
</style>

<template>
  <md-card>
    <md-card-content>
      <!--HEADER-->
      <div class="md-layout md-alignment-center-space-between">

        <h1 class="md-title md-layout-item md-size-40">Most Popular Site</h1>

        <div class="md-layout-item md-size-10">
          <md-button class="md-accent md-raised" @click="save">Save</md-button>
        </div>
      </div>

      <!--DETAILS-->
      <div class="md-layout md-alignment-center-space-between">
        <div class="md-layout-item md-size-30">
          <div class="md-layout-item md-small-size-30">
            <md-field class="disabled">
              <label for="id">Id</label>
              <md-input name="id" id="id" class="disabled" v-model="mostPopularSiteData.id" disabled="disabled"
                        readonly/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-30">
            <md-field class="disabled">
              <label for="address">Address</label>
              <md-input name="address" id="address" class="disabled" v-model="mostPopularSiteData.address"
                        disabled="disabled" readonly/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-30">
            <md-field class="disabled">
              <label for="iconpath">Logo</label>
              <md-input name="iconpath" id="iconpath" class="disabled" v-model="mostPopularSiteData.iconpath"
                        disabled="disabled" readonly/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-30">
            <md-field>
              <label for="position">Position</label>
              <md-input type="number" id="position" name="position" autocomplete="position"
                        v-model="mostPopularSiteData.position" aria-required="true"/>
            </md-field>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { FETCH_MOST_POPULAR_SITE, UPDATE_MOST_POPULAR_SITE } from '@/store/actions.type';

export default {
  name: 'Site',
  props: {
    id: String,
  },
  data: () => ({
    position: null,
  }),
  computed: {
    mostPopularSiteData() {
      return this.$store.getters.mostPopularSiteData;
    },
  },
  mounted() {
    this.initMostPopularSiteData(this.id);
  },
  methods: {
    initMostPopularSiteData(id) {
      this.fetchMostPopularSite(id);
    },
    fetchMostPopularSite(id) {
      this.$store.dispatch(FETCH_MOST_POPULAR_SITE, id)
        .then((response) => {
          this.$log.info('FETCH_MOST_POPULAR_SITE', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_MOST_POPULAR_SITE', error);
        });
    },
    save() {
      const payload = {
        id: this.mostPopularSiteData.id,
        position: this.mostPopularSiteData.position,
      };
      if (this.mostPopularSiteData.position) {
        this.$store.dispatch(UPDATE_MOST_POPULAR_SITE, payload)
          .then((response) => {
            this.$log.info('UPDATE_MOST_POPULAR_SITE', response);
            this.$toasted.show(response.message, {
              type: 'success',
              duration: 5000,
              icon: 'check',
            });
          })
          .catch((error) => {
            this.$log.error('REMOVE_MOST_POPULAR_SITE', error);
            this.$toasted.error(error.message, {
              type: 'error',
              duration: 5000,
              icon: 'alert',
            });
          });
      }
    },
  },
  watch: {
    id: {
      handler(val) {
        this.initSiteData(val);
      },
    },
  },
};
</script>
<style>
.disabled {
  color: #777777;
}

.md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: unset;
}
</style>
